<template>
  <div>
      <Banner />
      <NavBar>
        <el-breadcrumb-item style="line-height: 41px;font-size: 12px;">
          <router-link :to="{path:`/product/${productInfo.detail?.itemBelong}`}">产品与解决方案</router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item style="line-height: 41px;font-size: 12px;">
          <router-link :to="{path:`/product/${productInfo.detail?.itemBelong}`}">{{productInfo.fatherProductName}}</router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item style="line-height: 41px;font-size: 12px;">
          {{productInfo.detail?.itemName_cn}}
        </el-breadcrumb-item>
      </NavBar>
      <div id="content">
        <ModuleTitle :chinese="productInfo.detail?.itemName_cn" :english="productInfo.detail?.itemName_en" />
        
        <div id="MainContent" v-if="productInfo.haveData">
          <div class="ContentImg" v-if="productInfo.detail?.itemThumbnail">
            <img :src="productInfo.detail?.itemThumbnail" />
          </div>
          <div 
            class="ContentText"
            :style="{width:productInfo.detail?.itemThumbnail?'':'100%'}"
            v-html="productInfo.detail?.itemIntroduce"
          >
          </div>
        </div>
        <div v-if="!productInfo.haveData">
          <WithoutContent/>
        </div>
      </div>
  </div>
</template>
  
  <script>
  import Banner from '../components/common/Banner'
  import NavBar from '../components/common/NavBar'
  import ModuleTitle from '../components/common/ModuleTitle'
  import WithoutContent from '../components/common/WithoutContent'
  export default {
    name:'SolutionAndProduct',
    props:['id','title'],
    components:{
      Banner,
      NavBar,
      ModuleTitle,
      WithoutContent
    },
    data(){
      return{
        productInfo:{
          fatherProductName:null,
          detail:null,
          haveData:true
        }
      }
    },
    methods:{
      getProductInfo(id){
        this.$axios.get(`/read/getItem?id=${id}`)
        .then(res => {
          const result = res[0]
          if(!result.itemIntroduce){
            this.$set(this.productInfo,'haveData',false)
          }else{
            this.$set(this.productInfo,'haveData',true)
          }
          result.itemIntroduce = result?.itemIntroduce?.replaceAll('/n','<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
          this.$set(this.productInfo,'detail',result)
        })
      }
    },
    created(){
      this.getProductInfo(this.id)
      const fatherName = localStorage.getItem('fatherProductName')
      this.$set(this.productInfo,'fatherProductName',fatherName)
    },
    beforeRouteUpdate(to,from,next){
      this.getProductInfo(to.params.id)
      next()
    }
  }
  </script>

  <style scoped>
  #content{
    max-width: 1200px;
    margin: auto;
  }
  .el-breadcrumb__inner a{
    font-weight: normal;
    font-size: 12px;
    color: #333;
  }
  .el-breadcrumb__inner:hover a{
    color: #333;
  }
  .stepCardContainer{
    display: flex;
  }

  #MainContent{
    display: flex;
    justify-content: space-around;
  }
  .ContentImg{
    width: 43%;
    overflow: hidden;
    padding: 40px;
    box-sizing: border-box;
  }
  @media (max-width:1024px) {
    .ContentImg{
      width: 40%;
      padding: 20px;
      max-height: 578px;
    }
  }

  .ContentImg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .ContentText{
    width: 50%;
    padding: 40px;
    font-size: 28px;
    text-indent:2em;
    line-height: 150%;
    letter-spacing: 1.5px;
    font-family: 'Microsoft YaHei';
  }
  /* @media (min-width:1024px) {
    .ContentText{
      font-size: 1.05rem;
    }
  } */
  @media (max-width:640px) {
    .stepCardContainer{
      display: block;
    }
    .ContentImg{
      width: 100%;
    }
    .ContentText{
      width: 100%;
      box-sizing: border-box;
      font-size: 24px;
      line-height: 32px;
    }
    #MainContent{
      display: block;
    }
  }
  </style>